import React, { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import axios from 'axios';
import showdown from "showdown"
import ModalResponse from '../ModalResponse';
import ReactMarkdown from 'react-markdown';

export default function RequestCodeForm() {
  const [postingData, setPostingData] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    postcode: '',
    workplace: '',
    consent: 'yes'
  });


  // = = = = = = = = 
  // Forestry Query
  const data = useStaticQuery(graphql`
    query RequestCodeForm {
      unlistedForm: allMarkdownRemark(filter: {frontmatter: {form_title: {eq: "Request code form"}}}) {
        nodes {
          frontmatter {
            form_consent
          }
        }
      }
    }
  `)

  // = = = = = = = = 
  // Utils Functions

  // Convert Markdown to HTML
  const converter = new showdown.Converter();
  const htmlFormConsent = data.unlistedForm.nodes[0].frontmatter.form_consent;


  // = = = = = = = = 
  // Events Functions

  const displayResponseModal = () => {
    setShowResponse(true);
    setTimeout( () => setShowResponse(false), 3500)
  }

  // Add data to States
  const handleChange = e => {
    // Set Form Data
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

    // Add consent if user didn't interact with it's radio buttons
    if(formData.consent === undefined) {
      setFormData({
        ...formData,
        consent: 'yes'
      });
    }
  }

  // Handles Form Submition
  const handleSubmit = e => {
    e.preventDefault();
    let myForm = e.target;

    // Show spinner
    setPostingData(true);
    
    // Send to Netlify Forms
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": myForm.getAttribute('name'),
        ...formData
      }).toString()
    })
    .then(async (response) => {
      if (!response.ok) throw Error(response.statusText);
      // Send to Serverless Function
      axios.post('/.netlify/functions/mailGunFinal', {
        notificationMailgun: 'requestcode.notification',
        notificationSubject: 'Notification of request for voter registration code',
        confirmationMailgun: 'requestcode.confirmation',
        confirmationSubject: 'Confirmation of request for voter registration code',
        userName: `${formData.firstName} ${formData.lastName}`,
        userEmail: formData.email,
        userPhone: formData.phone,
        userAddress: formData.address,
        userPostcode: formData.postcode,
        userWorkplace: formData.workplace,
      })
    })
    .then(response => {
      // Hide spinner
      setPostingData(false);
      // Empty Form to clean up Form Fields
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        postcode: '',
        workplace: '',
        consent: 'yes'
      });
       // Show Modal Response
      displayResponseModal();
    })
    .catch((error) => {
      setPostingData(false);
      alert(error);
    })
  }

  return (
    <>
      <form 
        onSubmit={ (e) => handleSubmit(e) } 
        className="form form--page"
        name="Request Code Form"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        id="request-code-form"
      >
        <input type="hidden" name="form-name" value="Request Code Form" />

        <div className="form__wrapper">
          <div className="field-group">
            <div className="input-wrapper">
                <div className="input-wrapper">
                <input 
                  onChange={(e) => handleChange(e)} 
                  type="text" 
                  value={formData.firstName} 
                  name="firstName" 
                  id="first-name" 
                  placeholder="First Name*"
                  required
                />
                <span className={`input__custom-label ${ formData.firstName ? 'show' : '' }`}>First Name</span>
              </div>
            </div>

            <div className="input-wrapper">
                <div className="input-wrapper">
                <input 
                  onChange={(e) => handleChange(e)} 
                  type="text" 
                  value={formData.lastName} 
                  name="lastName" 
                  id="last-name" 
                  placeholder="Last Name*"
                  required
                />
                <span className={`input__custom-label ${ formData.lastName ? 'show' : '' }`}>Last Name</span>
              </div>
            </div>
          </div>

          <div className="field-group">
            <div className="input-wrapper">
              <div className="input-wrapper">
                <input 
                  onChange={(e) => handleChange(e)} 
                  type="email" 
                  value={formData.email} 
                  name="email" 
                  className="required email" 
                  id="email" 
                  placeholder="Email Address*"
                  required
                />
                <span className={`input__custom-label ${ formData.email ? 'show' : '' }`}>Email</span>
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-wrapper">
                <input 
                  onChange={(e) => handleChange(e)} 
                  type="tel" 
                  value={formData.phone} 
                  name="phone" 
                  className="required phone" 
                  id="phone" 
                  placeholder="Mobile Phone"
                />
                <span className={`input__custom-label ${ formData.phone ? 'show' : '' }`}>Phone</span>
              </div>
            </div>
          </div>

          <div className="field-group">
            <div className="input-wrapper" style={{ width: '100%' }}>
              <div className="input-wrapper">
                <input 
                  onChange={(e) => handleChange(e)} 
                  type="address" 
                  value={formData.address} 
                  name="address" 
                  className="required address" 
                  id="address" 
                  placeholder="Address*"
                  required
                  style={{ width: '100%' }}
                />
                <span className={`input__custom-label ${ formData.address ? 'show' : '' }`}>Address</span>
              </div>
            </div>
          </div>

          <div className="field-group">
            <div className="input-wrapper">
              <div className="input-wrapper">
                <input 
                  onChange={(e) => handleChange(e)} 
                  value={formData.postcode} 
                  name="postcode" 
                  className="required postcode" 
                  id="postcode" 
                  placeholder="Postcode*"
                  required
                />
                <span className={`input__custom-label ${ formData.postcode ? 'show' : '' }`}>Postcode</span>
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-wrapper">
                <input 
                  onChange={(e) => handleChange(e)} 
                  value={formData.workplace} 
                  name="workplace" 
                  className="required postcode" 
                  id="workplace" 
                  placeholder="Workplace (if applicable)"
                  required
                />
                <span className={`input__custom-label ${ formData.workplace ? 'show' : '' }`}>Workplace (if applicable)</span>
              </div>
            </div>
          </div>

        </div>


        <div className="form__consent">

          <ReactMarkdown className="consent__text">{htmlFormConsent}</ReactMarkdown>

          <div className="consent__inputs">

            <div className="radio-wrapper">
              <input 
                onChange={ (e) => handleChange(e) } 
                id="yes" 
                name="consent" 
                type="radio" 
                value="yes" 
                checked={ (formData.consent === 'yes' || formData.consent === undefined) ? true : false} 
              />
              <label htmlFor="yes">Yes</label>
            </div>

            <div className="radio-wrapper">
              <input 
                onChange={ (e) => handleChange(e) } 
                id="no" 
                name="consent" 
                type="radio" 
                value="no" 
                checked = { formData.consent === 'no' ? true : false} 
              />
              <label htmlFor="no">No</label>
            </div>
            
          </div>  
        </div>

        <div className="button-group">
          {/* <button type="submit">Submit</button> */}
          <div className="button button--submit">
            {
              postingData !== true
                ? <input readOnly type="submit" value="Submit" name="submit" id="company-submit" />
                
                : <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
            }
          </div>
        </div>          
      </form>
      {
        showResponse ? <ModalResponse /> : ''
      }
    </>
  )
}